<template>
	<form class="main-wrapper mt-4 mb-3" @submit.prevent="handleSubmit">
		<BannerGeneral
			:id="id"
			ref="general"
			name-label="Highlight banner name*"
			:name="name"
			:priority="priority"
			:status="status"
			:widgets="widgets"
			:widget-type="widgetType"
			show-widget
			@update:name="name = $event"
			@update:priority="priority = $event"
			@update:status="status = $event"
			@update:widgets="widgets = $event"
		/>

		<BannerDate
			ref="date"
			:status="status"
			:start-date="startDate"
			:start-time="startTime"
			:end-date="endDate"
			:end-time="endTime"
			@update:startDate="startDate = $event"
			@update:startTime="startTime = $event"
			@update:endDate="endDate = $event"
			@update:endTime="endTime = $event"
		/>

		<BannerContentWrapper
			ref="contentLeft"
			:banner-link="bannerLinkLeft"
			desktop-img="/assets/images/highlight-left-banner-desktop.svg"
			mobile-img="/assets/images/highlight-left-banner-mobile.svg"
			required-link
			@update:bannerLink="bannerLinkLeft = $event"
		>
			<template #title>
				Left banner
			</template>
			<BaseMediaSelectorBox
				ref="leftBannerDesktop"
				v-model="leftBannerDesktop"
				title="Desktop banner"
				no-image-text="No banner"
				show-helper
				width="1000"
				height="694"
				class="mt-4"
				required
			/>

			<BaseMediaSelectorBox
				ref="leftBannerMobile"
				v-model="leftBannerMobile"
				title="Mobile banner"
				no-image-text="No banner"
				show-helper
				width="1000"
				height="537"
				class="mt-4"
				required
			/>
		</BannerContentWrapper>

		<BannerContentWrapper
			ref="contentTopRight"
			desktop-img="/assets/images/highlight-top-right-banner-desktop.svg"
			mobile-img="/assets/images/highlight-top-right-banner-mobile.svg"
			:required-link="!!topRightBanner"
			:banner-link="bannerLinkTopRight"
			@update:bannerLink="bannerLinkTopRight = $event"
		>
			<template #title>
				Top - Right banner
			</template>
			<BaseMediaSelectorBox
				ref="topRightBanner"
				v-model="topRightBanner"
				title="Desktop and mobile banner"
				no-image-text="No banner"
				show-helper
				width="1000"
				height="326"
				class="mt-3 mb-4"
				required
			/>
		</BannerContentWrapper>

		<BannerContentWrapper
			ref="contentBottomRight"
			desktop-img="/assets/images/highlight-bottom-right-banner-desktop.svg"
			mobile-img="/assets/images/highlight-bottom-right-banner-mobile.svg"
			:required-link="!!bottomRightBanner"
			:banner-link="bannerLinkBottomRight"
			@update:bannerLink="bannerLinkBottomRight = $event"
		>
			<template #title>
				Bottom - Right banner
			</template>
			<BaseMediaSelectorBox
				ref="bottomRightBanner"
				v-model="bottomRightBanner"
				title="Desktop and mobile banner"
				no-image-text="No banner"
				show-helper
				width="1000"
				height="326"
				class="mt-4"
				required
			/>
		</BannerContentWrapper>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isLoading || isUpdating"
			:is-edit="isEditMode"
			:remove-text="isEditMode ? 'Remove banner' : null"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'BannerHighlightList'})"
			@onRemove="isEditMode ? $refs['modal-remove'].open() : null"
		/>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteBanner.bind(null, id)"
			title="Remove this banner?"
			description="By removing this, all banner info will be disappeared from the list and linked widgets."
			@onSuccess="$router.push({ name: 'BannerHighlightList'})"
		/>
	</form>
</template>

<script>
import { mapActions } from 'vuex';

import BannerGeneral from '@/components/BannerGeneral.vue';
import BannerDate from '@/components/BannerDate.vue';
import BannerContentWrapper from '@/components/BannerContentWrapper.vue';
import BaseMediaSelectorBox from '@/components/BaseMediaSelectorBox.vue';

import { BANNER_TYPE, BANNER_IMAGE_TYPE } from '../enums/banners';
import { convertDateTimeToUTC } from '../assets/js/helpers';

export default {
	name: 'BannerHighlightForm',

	components: {
		BannerGeneral,
		BannerDate,
		BannerContentWrapper,
		BaseMediaSelectorBox,
	},

	props: {
		isEditMode: {
			type: Boolean,
			default: false,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isUpdating: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: null,
		},
		bannerType: {
			type: String,
			default: BANNER_TYPE.highlight,
		},
	},

	data() {
		return {
			id: null,
			name: '',
			priority: 0,
			widgets: [],
			status: true,
			startDate: null,
			startTime: '00:00',
			endDate: null,
			endTime: '23:59',
			leftBannerDesktop: null,
			leftBannerMobile: null,
			topRightBanner: null,
			bottomRightBanner: null,
			bannerLinkLeft: '',
			bannerLinkTopRight: '',
			bannerLinkBottomRight: '',
		};
	},

	computed: {
		widgetType() {
			const widget = {
				hero: 'hero_banner',
				highlight: 'highlight_banner',
				small: 'small_banner',
				// In case text widget type and banner type are same
				text_widget: 'text_widget',
				picture_thumbnail: 'picture_thumbnail',
			};

			return widget[this.bannerType];
		},
	},

	watch: {
		data: {
			immediate: true,
			handler(value) {
				if (this.isEditMode && !this.isUpdating) {
					this.id = value.id;
					this.name = value.name;
					this.priority = value.priority;
					this.status = value.status;
					this.startDate = value.startDate;
					this.startTime = value.startTime;
					this.endDate = value.endDate;
					this.endTime = value.endTime;
					this.leftBannerDesktop = value.banners.leftBannerDesktop;
					this.leftBannerMobile = value.banners.leftBannerMobile;
					this.topRightBanner = value.banners.topRightBanner;
					this.bottomRightBanner = value.banners.bottomRightBanner;
					this.bannerLinkLeft = value.banners.bannerLinkLeft;
					this.bannerLinkTopRight = value.banners.bannerLinkTopRight;
					this.bannerLinkBottomRight = value.banners.bannerLinkBottomRight;
					this.widgets = value.widgets;
				}
			},
		},
	},

	methods: {
		...mapActions({
			deleteBanner: 'banners/deleteBanner',
		}),

		handleChangeEndDate(value) {
			this.endDate = value;
		},

		addBanner(file, type, link) {
			const result = {};
			result.file = file ? file.id : null;
			result.type = type;

			if (link) {
				result.link = link;
			}

			return result;
		},

		async handleSubmit() {
			const generalRef = this.$refs.general.$v;
			const dateRef = this.$refs.date.$v;

			const contentLeftRef = this.$refs.contentLeft.$v;
			const leftBannerDesktopRef = this.$refs.leftBannerDesktop.$v;
			const leftBannerMobileRef = this.$refs.leftBannerMobile.$v;

			const contentTopRightRef = this.$refs.contentTopRight.$v;
			const topRightBannerRef = this.$refs.topRightBanner.$v;

			const contentBottomRightRef = this.$refs.contentBottomRight.$v;
			const bottomRightBannerRef = this.$refs.bottomRightBanner.$v;

			generalRef.$touch();
			dateRef.$touch();

			contentLeftRef.$touch();
			leftBannerDesktopRef.$touch();
			leftBannerMobileRef.$touch();

			contentTopRightRef.$touch();
			topRightBannerRef.$touch();

			contentBottomRightRef.$touch();
			bottomRightBannerRef.$touch();

			if (
				!generalRef.$invalid &&
				!dateRef.$invalid &&
				!contentLeftRef.$invalid &&
				!leftBannerDesktopRef.$invalid &&
				!leftBannerMobileRef.$invalid &&
				!contentTopRightRef.$invalid &&
				!topRightBannerRef.$invalid &&
				!contentBottomRightRef.$invalid &&
				!bottomRightBannerRef.$invalid
			) {
				const data = {
					name: this.name,
					type: BANNER_TYPE.highlight,
					priority: Number(this.priority, 10),
					is_visible: this.status,
					start_at: convertDateTimeToUTC(this.startDate, this.startTime),
					end_at: convertDateTimeToUTC(this.endDate, this.endTime),
					banners: [
						this.addBanner(
							this.leftBannerDesktop,
							BANNER_IMAGE_TYPE.homepage_highlight_1_banner_image,
							this.bannerLinkLeft,
						),
						this.addBanner(
							this.leftBannerMobile,
							BANNER_IMAGE_TYPE.homepage_highlight_1_mobile_banner_image,
							this.bannerLinkLeft,
						),
						this.addBanner(
							this.topRightBanner,
							BANNER_IMAGE_TYPE.homepage_highlight_2_banner_image,
							this.bannerLinkTopRight,
						),
						this.addBanner(
							this.bottomRightBanner,
							BANNER_IMAGE_TYPE.homepage_highlight_3_banner_image,
							this.bannerLinkBottomRight,
						),
					],

					widget_ids: this.widgets.map((widget) => widget.id),
				};

				this.$emit('onSubmit', data);
			}
		},

		handleRemove() {
			this.$emit('onRemove');
		},
	},
};
</script>

<style lang="scss" scoped>
	.remove-banners {
		font-size: rem(14);
		color: $color-orange;

		align-items: center;

		cursor: pointer;
	}
</style>
